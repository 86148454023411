<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/questionario-telemedicina">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/questionario-telemedicina"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <h5 class="mb-4">{{ title }}</h5>
                    <div class="">
                        <div class="grid">
                            <div class="field col-6">
                                <label for="name">Pergunta</label>
                                <InputText v-model.trim="form.pergunta" required="true" autofocus
                                    autocomplete="off" placeholder="Informe a pergunta"
                                    :class="{ 'p-invalid': submitted && !form.pergunta }" />
                                <small class="p-error" v-if="submitted && !form.pergunta">Pergunta é obrigatória.</small>
                            </div>
                            <div class="field col-6">
                                <label for="ordem">Ordem</label>
                                <InputNumber id="ordem" v-model="form.ordem" autofocus autocomplete="off" placeholder="Informe a ordem" />
                            </div>
                        </div>
                        <div class="grid">
                          <div class="field col-6 mt-5">
                              <div class="field-checkbox">
                                  <InputSwitch v-model="checkedMostraPaciente" @click="clickMostraPaciente" />
                                  <label for="binary" class="mr-2 mb-1">Mostra pacientes</label>
                              </div>
                          </div>
                          <div class="field col-6">
                            <label for="customers">Pacientes</label>
                            <MultiSelect id="customers" class="selects" placeholder="Selecione..." v-model="form.clientes"
                                :filter="true" @filter="loadCustomers"
                                :maxSelectedLabels="10" :virtualScrollerOptions="{ itemSize: 60 }" :options="clientes"
                                optionLabel="name" dataKey="id" :disabled="!checkedMostraPaciente" />
                          </div>
                        </div>
                        <div class="grid">
                          <div class="field col-6">
                            <label for="tipo">Tipo</label>
                              <Dropdown
                                  id="tipo"
                                  class="selects"
                                  v-model="form.tipo"
                                  :options="tipos"
                                  @change="onChangeTipos"
                                  optionValue="value"
                                  optionLabel="label"
                                  placeholder="Selecione..."
                                  :class="{ 'p-invalid': submitted && !form.tipo }"
                              />
                              <small class="p-error" v-if="submitted && !form.tipo">Tipo é obrigatório.</small>
                          </div>
                          <div class="field col-6">
                            <label for="opcoes">Opções</label>
                            <Chips
                              id="opcoes"
                              class="chips"
                              v-model="form.opcoes"
                              :disabled="form.tipo !== 'ME' && form.tipo !== 'CB'"
                              placeholder="Informe as opções"
                              :class="{ 'p-invalid': submitted && !form.opcoes && (form.tipo === 'ME' || form.tipo === 'CB') }"
                            />
                            <small class="p-error" v-if="submitted && !form.opcoes && (form.tipo === 'ME' || form.tipo === 'CB')">Opções é obrigatório.</small>
                          </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';

export default {
    data() {
        return {
            form: {},
            submitted: false,
            clientes: [],
            loadingClientes: false,
            tipos: [
              {
                value: "ME",
                label: "Múltipla escolha"
              },
              {
                value: "CB",
                label: "Combo"
              },
              {
                value: "DI",
                label: "Dissertativa"
              },
              {
                value: "IN",
                label: "Inteiro"
              }
            ],
            checkedMostraPaciente: false,
        };
    },
    created() {
        this.service = new BaseService('/questionario-telemedicina');
    },
    async mounted() {
      this.$serviceCliente = new BaseService('/customers/findall-semfiltro-tenant');
      await Promise.all([
          this.loadCustomers()
      ]);
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar questionário telemedicina' : 'Adicionar questionário telemedicina';
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;

            const formatForm = {
                ...this.form,
                mostrarPaciente: this.checkedMostraPaciente,
                clientes: this.form.clientes?.map((customer) => {return {customerId: customer.id}}),
            };

            this.form = formatForm;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.form.pergunta) return true;
            if ((this.form.tipo === 'ME' || this.form.tipo === 'CB') && !this.form.opcoes) return true;
            if (!this.form.tipo) return true;
        },
        onChangeTipos(e) {
          if (e.value !== 'ME' && e.value !== 'CB') {
            this.form.opcoes = null
          }
        },
        async clickMostraPaciente() {
          this.checkedMostraPaciente = !this.checkedMostraPaciente;
          this.form.clientes = [];
        },
        async loadCustomers(event) {
            const companyId = this.form.companies?.map(c => c.id);
            const { data } = await this.$serviceCliente.findAll({ limit: 9999, page: 1, filter: event?.value, companyId });
            this.clientes = data.items;
        },
    }
};
</script>

<style scoped lang="scss">
.selects {
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
}

.chips {
    height: 42px;
}
</style>
